<template>
  <v-container class="leaf--text">
    <v-btn
      fixed
      right
      top
      class="sign-in-button ma-3 text-uppercase"
      color="leaf leaf--text elevation-0"
      text
      :style="{margin: '2vmin 2vmin 0 0 !important'}"
      @click="skipOnboarding"
    >
      <span :style="{'color': colors.leaf, 'border-color': colors.leaf}">
        {{ isAuthenticated ? 'Skip' : 'Sign in' }}
      </span>
    </v-btn>
    <div
      class="d-flex flex-column align-center justify-center text-center"
      style="height: 100vh"
    >
      <p>
        Watch this intro video to learn how to use the app:
      </p>
      <video
        ref="video"
        src="./video.mp4"
        class="video"
        :height="playerHeight"
        :width="playerWidth"
        controls
        autoplay
        :controlsList="`nodownload ${$vuetify.breakpoint.smAndUp ? 'nofullscreen' : ''} noremoteplayback`"
      />
      <p
        class="text-fade mt-4"
        :class="[$vuetify.breakpoint.smAndUp ? 'title' : 'body-2', showCaptions ? 'show' : '']"
      >
        {{ currentCaptionText }}
      </p>
    </div>
    <v-tooltip
      v-if="showNextButton"
      v-model="showTooltip"
      left
    >
      <template
        #activator="{ on }"
        top
      >
        <v-btn
          fixed
          right
          bottom
          fab
          x-large
          color="white"
          class="mr-5 mb-5"
          elevation="0"
          @click="next"
          v-on="on"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </template>
      Click to go to the next page
    </v-tooltip>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

const captionData = [
  { startTime: 0, text: 'Use the new element form to add materials, sinks and operations to your project.' },
  { startTime: 17, text: 'Edit quantities of your newly added elements using the sliders or text boxes.' },
  { startTime: 28, text: "Watch as your project's carbon footprint is populated based on your inputs" },
  { startTime: 50, text: 'Select the regional growth zone of the plants in your project.' },
  { startTime: 55, text: 'Then enter your plant type and quantity to see carbon sequestration.' },
  { startTime: 90, text: 'Lastly, select your site impacts and operations.' },
  { startTime: 96, text: 'Use the design suggestions view to get climate positive recommendations.' },
  { startTime: 107, text: 'View and download the scorecard to understand and improve your results.' },
  { startTime: 114, text: 'Edit your project details anytime on the summary page.' },
  { startTime: 120, text: 'Coming back to your projects page to create or delete projects.' }
]

export default {
  name: 'OnboardingGuide',
  data () {
    return {
      currentCaptionText: null,
      interval: null,
      showCaptions: false,
      showNextButton: false,
      showTooltip: false
    }
  },
  computed: {
    ...mapState(['colors']),
    playerHeight () {
      const win = this.$vuetify.breakpoint
      if (win.height < win.width) {
        return win.height - 230
      } else {
        return undefined
      }
    },
    playerWidth () {
      const win = this.$vuetify.breakpoint
      if (win.width < win.height) {
        return win.width - 30
      } else {
        return undefined
      }
    },
    isAuthenticated () {
      return Boolean(this.$store.state.userId)
    }
  },
  created () {
    this.$store.commit('setIsOnboarding', true)
    // this.$router.push('/location')
  },
  mounted () {
    this.runCaptions()
    this.$refs.video.onloadstart = () => {
      setTimeout(() => {
        this.showCaptions = true
      }, 1000)
    }
  },
  beforeDestroy () {
    window.clearInterval(this.interval)
  },
  methods: {
    async runCaptions () {
      const captions = captionData.map((x, i) => ({ ...x, endTime: captionData[i + 1] ? captionData[i + 1].startTime : Infinity }))
      this.interval = window.setInterval(() => {
        const timestamp = this.$refs.video.currentTime
        this.currentCaptionText = captions.find(x => timestamp >= x.startTime && timestamp < x.endTime).text
        if (timestamp > this.$refs.video.duration - 2) {
          this.showNextButton = true
          this.showTooltip = true
          window.clearInterval(this.interval)
          setTimeout(() => {
            this.currentCaptionText = 'Login now to begin your project.'
          }, 4000)
        }
      }, 300)
    },
    skipOnboarding () {
      this.$store.commit('setIsOnboarding', false)
      this.$router.push('/start')
    },
    next () {
      if (this.isAuthenticated) {
        this.$router.push('/projects/new')
      } else {
        this.$router.push(`/start`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.video {
  outline: none;
}
.text-fade {
  opacity: 0;
  transition: opacity 1s;
  &.show {
    opacity: 1;
  }
}
</style>
